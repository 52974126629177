import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { UserInterface } from "../api/types";
import { ReactTable, TableColumn } from "../../components/table";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { TableSkeleton } from "../../components/skeletons/table";
import { useGetUsersQuery } from "../api/repository";
import { useNavigate } from "react-router-dom";
import { ActionColumn } from "../../components/actionColumn";

export const UserList = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [{ page, perPage }, setPagination] = useState({
    page: 1,
    perPage: 15,
  });
  const userQuery = useGetUsersQuery({
    page,
    per_page: perPage,
  });
  const navigate = useNavigate();

  const columns: TableColumn<UserInterface>[] = useMemo(
    () => [
      {
        header: "column_names.username",
        accessor: "username",
      },
      {
        header: "column_names.email",
        accessor: "email",
      },
      {
        cell: (row) => <ActionColumn editLink={`/users/${row.id}/edit`} />,
      },
    ],
    []
  );

  useEffect(() => {
    if (userQuery.data) {
      setUsers(userQuery.data.data);
    }
  }, [userQuery.data, userQuery.isLoading]);

  return (
    <React.Fragment>
      <Box flex={1} flexDirection={"column"}>
        <Box
          paddingBottom={4}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography fontWeight={700} variant="h5">
            {t("users.title")}
          </Typography>
          <Button
            variant={"contained"}
            onClick={() => navigate("/users/create")}
            color={"primary"}
          >
            {t("users.create")}
          </Button>
        </Box>
        {userQuery.isLoading ? (
          <TableSkeleton />
        ) : (
          <React.Fragment>
            <Box flex={1} display={"flex"} flexDirection={"column"} rowGap={5}>
              <Card>
                <CardContent>
                  <ReactTable
                    paginated
                    perPage={perPage}
                    currentPage={page - 1}
                    onPaginate={(page) =>
                      setPagination({
                        page: page.page + 1,
                        perPage: page.perPage,
                      })
                    }
                    data={users}
                    columns={columns}
                    count={userQuery.data?.pagination.total}
                  />
                </CardContent>
              </Card>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};
