import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
import { SettingInterface } from "../api/types";
import { useGetSettingsQuery } from "../api/repository";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { TableSkeleton } from "../../components/skeletons/table";
import { ReactTable, TableColumn } from "../../components/table";
import { useNavigate } from "react-router-dom";
import { ActionColumn } from "../../components/actionColumn";
import { DataTypeEnum } from "../../../app/types";

export const SettingList = () => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<SettingInterface[]>([]);
  const settingQuery = useGetSettingsQuery();
  const navigate = useNavigate();

  const columns: TableColumn<SettingInterface>[] = useMemo(
    () => [
      {
        header: "column_names.key",
        cell: (row) => t(`settings.keys.${row.key}`),
      },
      {
        header: "column_names.value",
        accessor: "value",
        cell: (row) =>
          row.data_type === DataTypeEnum.BOOL
            ? Boolean(row.value)
              ? "Tak"
              : "Nie"
            : row.value,
      },
      {
        cell: (row) => <ActionColumn editLink={`/settings/${row.id}/edit`} />,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (settingQuery.isSuccess && settingQuery.data) {
      setSettings(settingQuery.data);
    }
  }, [settingQuery.data, settingQuery.isSuccess]);

  return (
    <React.Fragment>
      <Box flex={1} flexDirection={"column"}>
        <Typography marginBottom={4} fontWeight={700} variant="h5">
          {t("settings.title")}
        </Typography>
        {settingQuery.isLoading ? (
          <TableSkeleton />
        ) : (
          <React.Fragment>
            <Box flex={1} display={"flex"} flexDirection={"column"} rowGap={5}>
              <Card>
                <CardContent>
                  <ReactTable data={settings} columns={columns} />
                </CardContent>
              </Card>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};
